import compact from 'lodash-es/compact';
import pickBy from 'lodash-es/pickBy';

const href = ( path: string, params: { [key: string]: string }) => {
  const paramsSeparator = path && path.indexOf( '?' ) === -1 ? '?' : '&';

  return compact([ path, new URLSearchParams( pickBy( params )).toString() ]).join( paramsSeparator );
};

export const URLHelper = { href };
